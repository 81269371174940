import { createTheme } from '@material-ui/core/styles';

// variables
// ==> Dimensions
// const maxWidth = "1100px";
const maxWidth = "100%";
const headerHeight = "65px";
const drawerWidth = 260;

// ==> Colors
const primaryColor = "#38c7d5";
const primaryColorHover = "#1c9ca9";
const secondaryGradientColor = "#7fe2ee";
const tertiaryColor = "#333542";
const darkTertiaryColor = "#22232c";
const fontColor = "#1a1b39";
const iconColor = "#3f51b5";
const borderColor = "#9b9bff";
const dangerColor = "#f33223";
const successColor = "#449d48";
const warnColor = "#CCA300 ";
const whiteColor = "#ffffff";
const lightGrayColor = "#f7f8fb";
const blackColor = "#000";

// ==> Shades
const cardShadow = "0 7px 15px 0 rgba(0, 0, 0, 0.05)";

// ==> Theme
export const theme = createTheme({
  typography: {
    fontFamily: "BahijJanna",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "BahijJanna",
      },
    },
  },
});

export {
  // diminsions
  maxWidth,
  headerHeight,
  drawerWidth,
  // colors
  primaryColor,
  primaryColorHover,
  secondaryGradientColor,
  tertiaryColor,
  darkTertiaryColor,
  fontColor,
  iconColor,
  borderColor,
  dangerColor,
  successColor,
  warnColor,
  lightGrayColor,
  whiteColor,
  blackColor,
  // Shades
  cardShadow,
};
